<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="设置个性签名"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
            <template #right>
                <p class="blue" @click="save_sign">保存</p>
            </template>
        </van-nav-bar>
        <div class="Personality">
            <textarea placeholder="请设置你的个性签名~" v-model="personality" maxlength="100"></textarea>
            <p class="Personality-text">{{personality.length}}/100</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Personality",
        data() {
            return {
                personality: this.$route.query.personality,
            }
        },
        methods:{
            save_sign(){
              this.$axios.post('/api/save_sign',{
                  sign:this.personality
              })
                .then(res=>{
                    this.$toast.success('修改成功')
                    this.$router.go(-1)
                })
            },
        }
    }
</script>

<style scoped>
    .Personality-text {
        color: #BFC2CC;
        font-size: 24px;
        text-align: right;
    }

    .Personality {
        margin: 40px;
        background: rgba(242, 245, 248, 1);
        border-radius: 20px;
        padding: 40px 20px;
    }

    .blue {
        color: #4D77FD;
        font-size: 30px;
    }

    textarea::-webkit-input-placeholder {
        font-size: 28px;
        color: #BFC2CC;
        line-height: 44px;
    }

    textarea {
        border: none;
        height: 300px;
        width: 100%;
        background: none;
        font-size: 28px;
        line-height: 40px;
        color: #303133;
    }
</style>